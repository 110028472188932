.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 33px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #123f9f;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  &:hover{
    background-color: #123f9f;
  }
}

.slider:before {
    position: absolute;
    content: "";
    height: 22px;
    width: 22px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: 0.4s;
}

input:checked + .slider {
  background-color:  #ff6633;
}

input:focus + .slider {
  box-shadow: 0 0 3px #53affa;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
  border: 2px solid #2f72ff;
}

.slider.round:before {
  border-radius: 50%;
}

//   ============================================

.subscribtion-banner {
  height: 400px;
  background: #1646ab;
  color: #fff;
  text-align: center;
  position: relative;
  .ban-txt {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    margin: auto;
    left: 0;
    right: 0;
    .ban-head {
      font-size: 4rem;
      font-weight: 700;
      margin-bottom: 30px;
    }
    b {
      display: block;
      padding: 10px;
      border: 2px dashed #ff6633;
      width: max-content;
      margin: 30px auto;
      font-size: 1.5rem;
      line-height: 1;
    }
    .toggle {
      display: flex;
      align-items: center;
      width: 200px;
      margin: auto;
      justify-content: space-between;
    }
  }
}

.each-price{
    border-radius: 15px;
    border: 1px solid #eee;
    padding: 50px 20px;
    text-align: center;
    min-height: 480px;
    &.recommend{
        display: flex;
        flex-direction: column;
        margin-top: -30px;
        box-shadow: 0px 14px 24px 0px rgb(194 195 195 / 75%);
        background-color: #1646ab;
        color: #fff !important;
        min-height: 510px;
        position: relative;
        .tag{
            background: #336be0;
            color: #fff;
            border-radius: 25px;
            position: absolute;
            top: -10px;
            left: 0;
            right: 0;
            margin: auto;
            width: max-content;
            font-size: 10px;
            padding: 3px 10px;
        }
        small, .offer{
            color: #7d9ee4;
        }
        h4,.price{
            color: #fff;
        }
    }
    small{
        color: #7f7f7f;
        font-size: 16px;
        display: inline-block;
        font-weight: 700;
        text-transform: uppercase;
    }
    h4{
        text-transform: uppercase;
        color: #111111;
        font-size: 24px;
        font-weight: 700;
    }
    .price{
        color: #1646ab;
        font-size: 54px;
        font-weight: 700;
    }
    .time{
        padding: 10px;
        border: 2px dashed #ff6633;
    }
    button{
        background: #ff6633;
        border-radius: 50px;
        padding: 10px;
        color: #fff;
        width: 100%;
        text-transform: uppercase;
        font-weight: 700;
        margin-bottom: 20px;
    }
    .offer{
        color: #b5b5b5;
    }
}

.subs-stat h4{
  font-size: 1.2rem;
}
.subs-stat h3{
  font-size: 1.45rem;
}
.subs-stat-cancel h3{
  font-size: 1.3rem;
}


.subs-stat{
  background: #fff;
    border-radius: 10px;
    box-shadow: 0 0 5px rgb(0 0 0 / 18%);
    height: auto;
    min-height: 250px;
    margin: 30px auto 0;
    padding: 40px 20px;
    width: 500px;
}

.act-color{
color: #0d6efd;
}
.subs-stat small{
font-style: italic;
font-size: 63%;
}
.btn-subs {
  border-radius: 20px;
  font-size: 18px;
}

@media only screen and (max-width:480px){
    .subscribtion-banner .ban-txt .ban-head {
        font-size: 1.8rem;
    }
    .subscribtion-banner .ban-txt {
        padding: 0 15px;
    }
    .subscribtion-banner .ban-txt b {
        width: 100%;
        font-size: 1rem;
    }
}